import React, { useContext } from 'react';
import Form from "../Contact/Form";
import { GlobalDataContext } from '../../context/context';

function FormHome() {
  const { rpdata } = useContext(GlobalDataContext);
  return (

    <>
      <div className='w-full px-16 lg:px-28 block lg:flex justify-center'>
        <div className='w-full lg:w-[60%] flex items-center justify-center p-6'>
          <img
            src={rpdata?.dbPrincipal?.logo}
            alt='logo'
            loading='lazy'
            className='w-[100%] mx-auto pt-3 pb-5'
          />
        </div>
        <div className='lg:w-[40%] w-full p-5 bg-white shadow-lg '>
          <h2 className='text-center'>Send Us A Message</h2>
          <div className='p-4'>
            <Form />
          </div>
        </div>
      </div>
    </>
  )
}

export default FormHome
